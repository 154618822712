import { pixelInit, pixelTrackPage } from "./src/fb-pixel";
import { navigate } from "gatsby";

if ('scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual';
}

export const onClientEntry = () => {
  try {
    pixelInit();
  } catch (e) {
    console.warn(e);
  }
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  if(location.search) {
    const referenceUserCode = new URLSearchParams(location.search).get("refu");
    const referenceUser = referenceUserCode && new TextDecoder().decode(
      Uint8Array.from(atob(referenceUserCode).split("").map(x => x.charCodeAt()))
    );
    if(referenceUser) {
      localStorage.setItem("refu", referenceUser);
      navigate(location.pathname);
    }
  }
  
  try {
    pixelTrackPage();
  } catch (e) {
    console.warn(e);
  }
};
